import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/entities/user';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-account-header',
  templateUrl: './account-header.component.html',
  styleUrls: ['./account-header.component.scss']
})
export class AccountHeaderComponent implements OnInit {

  @Input() showLogo: boolean = false;
  @Input() disconnect: boolean = false;
  @Input() home: boolean = false;
  @Input() mustBeLoggedIn : boolean = true;

  screenWidth: number;
  user!: User;

  constructor(private authService: AuthService) {
    this.screenWidth = window.innerWidth;

    this.authService.getUser().then((user) => {
      if (user) this.user = user;
    });
  }

  ngOnInit() {
  }

  disconnectUser() {
    this.authService.disconnect();
  }

}

<div class="modal-head">

    <div class="modal-head-title">
        <h2 class="u-h5">{{ 'authentication.password_modal.title' | translate }}</h2>
    </div>

</div>



<ng-container *ngIf="!message; else messageContainer">
    <div class="modal-body">
        <label>
            {{ 'authentication.password_modal.email' | translate }}
            <input type="text" [(ngModel)]="email">
        </label>
    </div>
    
    <div class="-end" align="end">
        <button mat-dialog-close>
            {{ 'general.cancel' | translate }}</button>
        <button class="-primary" [ngClass]="{'-disabled' : !email || email == '' }" (click)="reset()">
            {{ 'authentication.password_modal.confirm' | translate }}</button>
    </div>
</ng-container>
<ng-template #messageContainer>
    <div class="modal-body">
        {{ message }}
    </div>
    
    <div class="-end" align="end">
        <button class="-primary" mat-dialog-close>
            {{ 'general.close' | translate }}</button>
    </div>
</ng-template>
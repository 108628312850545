import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { RedirectGuard } from './guards/redirect.guard';
import { AuthenticationComponent } from './modules/authentication/authentication.component';
import { FirebaseComponent } from './modules/authentication/components/firebase/firebase.component';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { EmailValidationGuardService } from './services/auth/email-validation-guard.service';
import { RoleGuardService } from './services/auth/role-guard.service';
import { ErrorNotFoundComponent } from './shared/components/error-not-found/error-not-found.component';

const routes: Routes = [
  {
    path: "admin",
    canActivate: [RoleGuardService, EmailValidationGuardService],
    data: {
      expectedRole: 'admin'
    },
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
  },
  // { path: "email", canActivate: [EmailVerificationService], component: LoadingComponent },
  // {
  //   path: "sequence/:sequence_id", 
  //   redirectTo: "client/sequence/:sequence_id"
  // },
  { path: "connexion", component: AuthenticationComponent, data: { type: "login" }, canActivate: [RedirectGuard] },
  { path: "login", component: AuthenticationComponent, data: { type: "login" }, canActivate: [RedirectGuard] },
  { path: "inscription", component: AuthenticationComponent, data: { type: "signup" }, canActivate: [RedirectGuard] },
  { path: "signup", component: AuthenticationComponent, data: { type: "signup" }, canActivate: [RedirectGuard] },
  { path: "confirmation", canActivate: [AuthGuardService], component: AuthenticationComponent, data: { type: "confirmation" } },
  { path: "firebase", component: FirebaseComponent, },
  { path: "", component: AuthenticationComponent, data: { type: "login" }, canActivate: [RedirectGuard] },
  { path: "**", component: ErrorNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), TranslateModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }

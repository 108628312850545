<ng-container *ngIf="!authenticationComponent.isLoading; else loading">
  <div class="c-button-auth">
    <button class="auth-button fb-button" (click)="authenticationComponent.loginFacebook()">
      <img src="/assets/facebook-logo.png" alt="">
      {{ 'authentication.login-page.connect_with_facebook' | translate }}
    </button>
    <button class="auth-button" (click)="authenticationComponent.loginGoogle()">
      <img src="/assets/google-logo.svg" alt="">
      {{ 'authentication.login-page.connect_with_google' | translate }}
    </button>
  </div>

  <div class="divider-or">{{ 'general.or' | translate }}</div>

  <h6>{{ 'general.email' | translate }}</h6>
  <input type="email" [(ngModel)]="authenticationComponent.email"
    (keydown.enter)="authenticationComponent.loginEmail()">
  <h6>{{ 'general.password' | translate }}</h6>
  <input type="password" [(ngModel)]="authenticationComponent.password"
    (keydown.enter)="authenticationComponent.loginEmail()">
  <div class="c-buttons">
    <p class="-center -noflex" [innerHTML]="'authentication.policies' | translate"></p>
    <p class="-center forgot"><a (click)="forgotPassword()">{{ 'authentication.forgot_password' | translate }}</a></p>
    <button class="-secondary -large -outline" (click)="authenticationComponent.loginEmail()">{{
      'authentication.connect' | translate
      }}</button>
    <p class="-center" routerLink="/signup" [queryParams]="{ 'origin' : authenticationComponent.originParam }" [innerHTML]="'authentication.signup_long' | translate"></p>
  </div>
</ng-container>
<ng-template #loading>
  <app-loading-secondary></app-loading-secondary>
</ng-template>
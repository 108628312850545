import { combineLatest } from "rxjs";

export const en = {
    general: {
        or: 'or',
        email: 'Email',
        password: 'Password',
        confirmation: 'Confirmation',
        disconnect: 'Logout',
        section: 'Section',
        administration: 'Administration',
        save: 'Save',
        add: 'Add',
        name: 'Name',
        to: 'To',
        from: 'From',
        cancel: 'Cancel',
        close: 'Close',
        greetings: 'Hi',
        'up-to-date': 'You are up to date! 🎉',
        done: 'done',
        next: 'Next',
        'return-to-site': 'Return to site',
        other: 'Other',
        delete: 'Delete',
        generic_delete_question: 'Are you sure you want to delete this?',
        generic_unassign_question: 'Are you sure you want to unnasign this?',
        letsgo: 'Continue',
        download_link: 'Download link',
        intro_message: 'It\'s a go! Welcome to your customized Dashboard 😉',
        good: 'I like it!',
        bad: 'Not for me!',
        ok: 'Alright!',
        back: 'Back',
        previous: 'Previous',
        welcome: 'Welcome! 🙏',
        saved: 'Saved!',
        'read-more': 'Read more',
        display_name_message: 'What\'s your name?',
        here_you_go: 'Here you go!',
        a_cool_name: 'Your cool name',
        search: 'Search',
        next_step: 'Next Step',
        alright: 'Alright!',
        warning: 'Attention',
        continue: 'Continue',
        selected: 'Selected',
        unassign: 'Unassign',
        yes: 'Yes',
        no: 'No',
        firstName: 'First Name',
        lastName: 'Last Name',
        pay: 'Pay',
        help: 'Need help?',
        lets_go: 'Let\'s go!',
        welcome_person: 'Welcome',
        waiting_steps: 'Here are the steps that await you:',
        your_needs: 'Indicate your needs',
        your_fav_vehicules: 'Choose your favorite vehicles',
        search_best: 'Search and receive the best ads',
        all_ressources: 'Access all Kiwiz resources to buy with confidence (inspections, insurance, guarantees, purchase contract between individuals, guides...)',
        logout: 'Logout',
        loadMore: 'Scroll down to load more vehicles.',
        language: 'Language',
        french: 'French',
        english: 'English',
    },
    firebase: {
        VERIFY_EMAIL: {
            title: 'Email validation',
            content: 'Your email was validated successfully!'
        },
        PASSWORD_RESET: {
            title: 'Password reset',
            content: 'Fill the form bellow to reset your password 😉'
        }
    },
    errors: {
        disconnect: 'You have been disconnected for security reasons.',
        forbidden: 'You don\'t have access to that ressource.',
        'missing-infos': 'Please fill the required fields.',
        'wrong-password': 'Your email/password is invalid.',
        'user-disabled': 'You account has been disabled.',
        'page-not-found': {
            title: 'Page not found',
            body: 'We cannot find the page you are looking for. You can still use our tools!'
        },
        redirect: {
            website: 'Visit our website',
            search: 'Find my car',
            inspection: 'Book an inspection',
        },
        'not-found': 'The ressource you are looking for cannot be found.',
        'invalid-email': 'The email is invalid.',
        'weak-password': 'Your password is weak.',
        'email-already-in-use': 'Your account already exists',
        error: 'An error occured'
    },
    authentication: {
        policies: 'By continuing, you\'re accepting our <a href=\'https://kiwiz.ca/conditions-generales-kiwiz/\' target=\'_blank\'>Terms of service</a>',
        connect: 'Connect',
        connect_long: 'Already have an account? <a>Login</a>',
        signup: 'Signup',
        signup_long: 'Don\'t have an account? <a>Register</a>',
        tagline: 'Buying a used car has never been that <span class=\'overline\'>simple.</span>',
        tagline_signup: 'Your <span class=\'overline\'>trusted</span> partner to buy your used car',
        login: 'Login',
        'login-page': {
            connect_with_google: 'Connect with Google',
            connect_with_facebook: 'Continue with Facebook'
        },
        'signup-page': {
            signup_with_google: 'Signup with Google',
            signup_with_facebook: 'Continue with Facebook',
            password_confirmation: 'Confirmation'
        },
        confirmation: 'Please verify your email address',
        'confirmation-page': {
            title: 'Please verify your email address',
            'resend-code': 'Send the confirmation link again.',
            message: 'A link has been sent to your email address. Use it to verifiy your account.'
        },
        forgot_password: 'I forgot my password',
        password_modal: {
            title: 'Reset my password',
            confirm: 'Reset',
            email: 'Email',
            message: 'A reset link has been sent to your email!'
        },
        password_reset_successful: 'Your password has been successfully reset!',
        new_code_sent: 'A new code has been sent'
    },
    client: {
        purchased_content: '<b>Congrats 🎉</b> <br> Buying a vehicle is a big adventure! Thanks for your trust ! 🍋',
        suggestions: {
            needs: 'According to your needs, here are the cars we suggest to you:',
            favorites: 'My favorite vehicles list',
            important: 'Add favorite vehicles to this list so that we can search for the best deals currently on the market. Don\'t panic: you can change and complete this list with other car models in the next step',
            add_car: 'Add a vehicule'
        },
        insurance: {
            title: 'Insurance'
        },
        pro: {
            title: 'To have a access to our pros, you have to be pro!',
            message: 'In order to benefit from all the content, simply continue your process and pay the fees. We\'ll help you find the best car for your needs.'
        },
        menu: {
            kiwiz: 'My Kiwiz',
            guides: 'My Guides',
            general: 'Home',
            admin: 'ADMIN DASHBOARD',
            sequence: 'My Adventure',
            insurance: 'My Insurance',
            preferences: 'My preferences',
            favorites: 'My selection'
        },
        informations: {
            get_your_kiwiz: 'Get your Kiwiz ! 🎉',
            get_kiwiz_message: 'A Kiwiz expert is currently looking for ads the corresponds to your needs! 😉',
            get_kiwiz_message_ads: 'A Kiwiz expert has found ads for you! Take a look!',
            see_my_kiwiz: 'See my kiwiz',
            continue_my_sequence: 'Continue my process'
        },
        guides: {
            title: 'You guides',
            no_guides: 'There are no guides available at this moment.',
            consult: 'Read article',
            download_pdf: 'Download pdf'
        },
        preferences: {
            modify_criteria: 'Modify your search criteria at any time to get the best possible ads!',
            consideration: 'These will be taken into consideration in the next search for advertisements that you launch.',
            vehicle: 'Vehicle 🚗',
            max_price: 'Maximum price',
            max_mileage: 'Maximum mileage',
            min_year: 'Minimum year',
            max_vehicle_age: 'Maximum age',
            body_work: 'Bodywork type',
            seller: 'Seller',
            search: 'Search',
            postal_code: 'Postal Code',
            manual: 'Manual',
            automatic: 'Automatic',
            no_preference: 'No preferences',
            motor_skills: 'Motor skills',
            traction_integral: 'All wheel drive',
            annual_mileage: 'Average annual mileage',
            my_priorities: 'My priorities',
            criteria: 'Criteria'
        },
        "model_algo": {
            "title_part": {
                "title": "Model Suggestions",
                "welcome_message": "Welcome to the Model Suggestion Page",
                "instructions": "Choose your criteria and we will suggest to you the best vehicles!",
                "ad_encouragement": "You can consider those in your next vehicle ad search.",
                "dont_fill_all": "* Psst! You don't have to fill everything",
                "return_website": "Return to website"
            },
            "typesList": {
                "sedan": "Sedan",
                "convertible": "Convertible",
                "pickup": "Pick-up",
                "van": "Van",
                "coupe": "Coupe",
                "hatchback": "Hatchback",
                "minivan": "Minivan",
                "wagon": "Wagon",
                "suv": "SUV",
                "roadster": "Roadster",
                no_preference: 'No preference',
            },
            "priorities": "Priorities",
            "prioritiesList": {
                "fuel_consumption": "Low consumption",
                "driving": "Fun factor",
                "interior": "Comfort",
                "reliability": "Reliability",
                "safety": "Security"
            },
            "requirement": "Requirements",
            "equipment": "Essential Equipment",
            "equipmentList": {
                "sunroof": "Sunroof",
                "heated_front_seats": "Heated front Seats",
                "gps": "GPS",
                "aux": "Auxiliary audio",
                "cd_player": "CD player",
                "apple_carplay": "Apple CarPlay compatibility",
                "lane_departure_warning": "Lane departure warning",
                "siriusxm": "Sirius XM",
                "bluetooth": "Audio Bluetooth",
                "remote_engine_start": "Remote engine start",
                "adaptive cruise control": "Adaptive cruise control",
                "forward_collision_warning": "Forward collision warning",
                "emergency_braking": "Emergency braking",
                "usbs": "Front USB ports",
                "bindspot_detection": "Blindspot detection",
                no_preference: 'No preference',
            },
            "autonomy": "Battery life (km)",
            "min_year": "Minimum year",
            "max_year": "Maximum year",
            "min_passengers": "Minimum number of passengers",
            "engine": "Engine",
            "engineList": {
                "electric": "Electric",
                "hybrid": "Hybrid",
                "gas": "Gas",
                no_preference: 'No preference',
            },
            "transmissionsList": {
                "manuel": "Manual",
                "automatic": "Automatic"
            },
            "drivetrain": "Drivetrain",
            "drivetrainList": {
                "traction": "Traction",
                "propulsion": "Propulsion",
                "integral": "All-wheel drive",
                no_preference: 'No preference',
            },
            "fuel_type": "Fuel Type",
            "fuelList": {
                "regular": "Regular",
                "regular_e85": "Regular E85",
                "super": "Super",
                "diesel": "Diesel",
                "premium": "Premium",
                no_preference: 'No preference',
            },
            "your_budget": "Your budget",
            "result_box": {
                "results": "Results",
                "description": "Click here to see your futur potential Kiwiz! 🥝",
                "button": "Click here to see results",
                "no_results": "Sorry, no result corresponds to your criteria"
            },
            "results": {
                "make": "MAKE",
                "model": "MODEL",
                "year": "YEAR",
                "score": "SCORE",
                "see_car": "See the vehicle"
            }
        },
        ads: {
            title: 'Your Kiwiz ads',
            see_ad: 'View ad',
            back: 'Back to my ads',
            vehicle_description: 'Vehicle Description',
            expert_note: 'Expert Note',
            client_note: 'Personal Notes',
            drivetrain: 'Drivetrain',
            fuel: 'Fuel',
            consumption: 'Fuel consumption',
            maintenance: 'Maintenance',
            purchased: 'I bought it!',
            view_on_site: 'View ad 🔗',
            vehicle_informations: 'Vehicle Informations',
            make: 'Make',
            model: 'Model',
            year: 'Year',
            version: 'Version',
            mileage: 'Mileage',
            price: 'Price',
            recommended_drivetrain: 'Recommended Drivetrain',
            ratings: 'Kiwiz expert ratings',
            pricing: 'Detailed Pricing',
            taxes: 'Taxes',
            warranty: 'Warranties 36months/60000km',
            licenses: 'Licenses',
            insurance: 'Insurance',
            buying_budget: 'Purchase cost',
            yearly_budget: 'Annual cost',
            show_warranty: 'Include warranties',
            inspect: 'Inspect',
            my_kiwiz: 'My Kiwiz',
            search_kiwiz: 'The kiwiz fishing was good! 🥝🚘',
            research_all_platforms: 'We took your favorites, preferences and criteria into consideration, and we searched all platforms for the best deals currently on the market (our technology only allows you to select cars at an attractive price 💡)',
            discover_announcement: 'Discover the ads found, and hurry, they will no longer be available soon! 🌟✅',
            hope_its_kiwiz: 'On espère que ce sera un kiwiz ! 🤞',
            kiwiz_ideal: '(As a reminder, a kiwiz is an ideal used car with no hidden flaws, bought with confidence! 🙌)',
            next_step: 'Next steps:',
            call_owner: 'Call the owner',
            guide_available: '(guide available in the "Resources" tab)',
            do: 'Do a',
            mecanical_inspection: 'mechanical inspection',
            assure_its_kiwiz: 'to make sure it\'s a kiwiz and not a lemon ... ("Inspections\ tab',
            continue: ' Continue',
            delete_announcement: 'Delete announcement',
            announcement_of: 'Announcement of ',
            kiwiz_ideal_meskiwiz: 'As a reminder, a kiwiz is an ideal used car with no hidden flaws, bought with confidence! 🙌',
            selected_announcement: 'Find below your ads selected and classified according to your favorite models.🚘',
            my_models: 'My models',
            fav_model: 'To receive the best ads available on the market, you must first inform us of your 3 favorite models on the page',
            general: '"Home" ',
            search_best_announcement: 'and click on "Find the best ads".',
            best_deals: 'Then we would go in search of the best deals.🚘',
            add_favorite: 'Add',
            remove_favorite: 'Remove',
            kiwiz_opinion: 'Kiwiz\'s opinion',
            technical_sheet: 'Technical sheet',
            price_and_kilometers: 'Average prices and mileage (per year)',
            average_price: 'Average price',
            average_kilometers: 'Average kilometers',
            remove_from_favorites: 'Remove from favorites',
            see_form: 'See the form',
            submission_here: '(make your submission here)'
        },
        inspections: {
            title: 'Book an Inspection',
            basic_inspection: 'Basic inspection',
            complete_inspection: 'Complete inspection',
            pick_inspection: 'Choose the inspection you want to perform below.',
            inspection_carried_out: 'Inspections carried out',
            find_under_inspection_carried_out: 'Find below the inspections you carried out',
            Voiture: 'Car',

            steps: {
                header: 'The Kiwiz inspection, in 4 simple steps',
                first_step: '1- Subscribe to the inspection here ',
                first_desc: 'The amount of the inspection will be taken from you in order to reserve it. If for some reason the inspection cannot take place, you will be reimbursed in full.',
                second_step: '2- Kiwiz will organize a meeting between the seller and the Kiwiz inspector as soon as possible',
                second_desc: 'You have nothing to do 🤩',
                third_step: '3- Inspector Kiwiz moves and inspects the vehicle rigorously, on 170 points',
                third_desc: 'You don\'t even have to be with him on site! If you choose the "Complete" inspection, Inspector Kiwiz will negotiate the vehicle for you, depending on the condition.',
                fourth_step: '4- You will receive the digital inspection report which tells you if the inspector considers that the car is a good deal or not',
                fourth_desc: 'Goodbye scams 💣🙌'
            },
            question_mark: 'Don\'t know which inspection to choose?',
            find_out_more: 'Find out more',
            tabs: {
                inspections_report: 'My reports',
                subscribe: 'Subscribe',
                no_report: 'You don\'t have an inspection report yet'
            },
            mandate_page: {
                clientName: "Client name",
                mandate: "Mandates",
                createMandate: "Create mandate",
                make_model_year: "Make/Model/Year",
                'ad_city' : 'City',
                sellerName: "Seller",
                informations: "Informations",
                url: "Seller contact details",
                postal_code: "Postal code",
                status: "Status",
                available: "Available",
                confirmed: "Confirmed",
                accepted: "Accepted",
                paid: "Paid",
                finished: "Completed",
                inReview: "In review",
                started: "In progress",
                accept: "Accept",
                confirm: "Confirm",
                made_on: "made on ",
                debut: "Start the inspection",
                completedInspection: "Inspection completed",
                date: "Date of inspection",
                mandate_inspect: "Inspection mandate",
                communicate: "Communicate with the seller !",
                time_and_date: "Find a date and time when the inspection can be performed.",
                enter_date: "Enter the date of the inspection",
                enter_time: "Enter the time of the inspection",
                open: "Show details",
                infoModal: {
                    title: "Informations",
                    inspectionDate: "Inspection scheduled for ",
                    sellerInfo: "Seller",
                    contactInfo: "Contact details: ",
                    adCity: "City: ",
                    customerInfo: "Customer",
                    lastName: "Last name: ",
                    firstName: "First name: ",
                    customerEmail: "Email: ",
                    customerPhone: "Phone number: ",
                    negoRequest: "Negotiation request: ",
                    extraRequest: "Extra request: ",
                    inspectorCallRequest : "Call request: ",
                    wantInspectorCall: "This customer wants to be called after the inspection.",
                    callNotNecessary: "This customer doesn't want to be called after the inspection.",
                    goToUrl: "See ad",
                    components : "Components",
                    component : "Component :",
                    vehicleDetails: "Vehicle details",
                    descriptionIssue: "Description of the issue :",
                    totalPrice: "Total price"
                }
            },
            reports: {
                title: 'My reports',

                inspection_details: {
                    "all_rights": "KIWIZ © All rights reserved.",
                    tab_synthesis: {
                        synthesis: 'Summary',
                        information_on_vehicle: 'IDENTIFICATION',
                        brand: 'Make',
                        model: 'Model',
                        version: "Trim",
                        year: 'Year',
                        mileage: 'Odometer',
                        date_of_inspection: 'Date of inspection',
                        time_of_inspection: 'Time of inspection',
                        city: 'City',
                        opinion_on_vehicle: 'Kiwiz\'s opinion',
                        recommended: 'Recommended for purchase',
                        general_comment_inspector: 'Comments',
                        inspector_kiwiz: 'INSPECTOR:',
                        first_paragraph: 'I am a mechanical designer, I work in machine safety for the industry and I studied mechanical engineering.',
                        second_paragraph: 'I am passionate about everything that runs since my childhood: cars, trucks, motorcycles ... I spend much of my free time to expand my knowledge of cars and mechanical sports!',
                        third_paragraph: 'If I started the kiwiz adventure, it\'s to share my expertise in mechanical engineering and to accompany my customers in the right choice of their future car.',
                        cost_of_repairing: 'Observations',
                        required_to_repair: 'Elements',
                        potential_to_repair: 'Potential for repair',
                        problem: 'Observations',
                        cost: 'Costs',
                        vin: 'VIN',
                        recommendation: "Recommendations",
                        color: 'Vehicle\'s color',
                        fuel: 'Fuel type',
                        firstHand: "First hand vehicle",
                        finalOffer: "Final negotiated offer"
                    },
                    tab_details: {
                        exterior: "Exterior",
                        road_test: 'ROAD TEST',
                        details: 'DETAILS',
                        elements: 'Elements',
                        tires: 'Tires',
                        comment: 'Comments',
                        not_verified: 'Not verified',
                        linear_acceleration: 'Constant acceleration',
                        temperature: 'Temperature and pressure build-up',
                        driving_line: 'Road holding',
                        function_direction: 'Steering',
                        easy_shifting: 'Gear shifting',
                        clutch_grip: 'Clutch grip',
                        running_gear: 'Running gear',
                        motor_vibration: 'No vibrations or abnormal noises',
                        brake_noises: 'Braking',
                        esthetic: 'ESTHETIC',
                        equipment: 'Equipments',
                        keys: 'Number of keys included: ',
                        manual: 'Manual included: ',
                        floor_mat: 'Floor mat included: ',
                        floor_mat_summer: 'Summer mat condition: ',
                        floor_mat_winter: 'Winter mat condition: ',
                        trunk_equipment: 'Equipment present: ',
                        additional_equipment: 'Additional equipment(s) included: ',
                        interior: 'Interior',
                        cleanliness: 'Cleanliness',
                        odor: 'Odor',
                        stains: "of stains/tears",
                        roof_stains: 'Roof',
                        seat_stains_front: 'Front seats',
                        seat_stains_back: 'Back seats',
                        trunk_stains: 'Trunk',
                        trunk_rust: 'of rust in the bottom of the trunk',
                        floor: 'Floor',
                        bodywork: 'Bodywork',
                        bumper: 'Bumpers',
                        windshieldwiper: 'Windshield wipers',
                        optical_block: 'Headlight units',
                        mirror: 'Mirrors',
                        wing: 'Car fenders',
                        door: 'Doors',
                        lower_body: 'Car sills',
                        rim: 'Rims',
                        hood: 'Hood',
                        trunk: 'Tailgate',
                        roof: 'Roof',
                        functional: 'FUNCTIONAL',
                        safety_belt: 'Safety belts',
                        front_seat_adjustment: 'Front seat adjustments',
                        audio_system: 'Audio system',
                        dashboard: 'Dashboard',
                        front_windshield_wiper: 'Front windshield wiper',
                        rear_windshield_wiper: 'Rear windshield wiper',
                        air_conditioning: 'Air conditioning',
                        electric_windows: 'Electric windows',
                        driver_mirror: 'Driver mirror',
                        interior_mirror: 'Interior mirror',
                        passenger_mirror: 'Passenger mirror',
                        glove_box: 'Glove box',
                        trunk_opening: 'Trunk opening',
                        additional_options: 'Additional options: ',
                        lights: 'Lights',
                        front: 'Front',
                        rear: 'Rear',
                        front_passing_lamps: 'Front passing lights',
                        front_main_beam: 'Front main beam headlights',
                        front_head_lights: 'Front headlights',
                        fog_lights: 'Fog lights',
                        turn_signal: 'Turn signals',
                        interior_light: 'Interior lights',
                        position_lights: 'Tail lights',
                        brake_lights: 'Brake lights',
                        reversing_lights: 'Reverse lights',
                        license_plate_lights: 'License plate lights',
                        windshield: 'Front windshield',
                        backwindshield: 'Rear windshield',
                        front_driver: 'Front driver: ',
                        rear_driver: 'Rear driver: ',
                        front_passenger: 'Front passenger: ',
                        rear_passenger: 'Rear passenger: ',
                        door_opening: 'Door openings',
                        tire_model_brand: 'Manufacturer of tire: ',
                        type_of_tire: 'Type of tire: ',
                        brake: 'Brake System',
                        tire_wear: 'Tread',
                        tire_pressure: 'Pressure',
                        tire_years: "Tires year: ",
                        tire_dimension: 'Tire size: ',
                        engine: 'ENGINE COMPARTMENT',
                        obd_scanner: 'Warning lights',
                        accesory_belt: 'Accessory belt',
                        air_filters: 'Condition of the air filters',
                        windshield_washer_fluid: 'Windshield washer fluid level',
                        brake_fluid: 'Brake fluid level',
                        powerSteering_fluid: 'Power steering fluid level',
                        coolant_fluid: 'Coolant level',
                        engine_oil_level: 'Engine oil level',
                        front_driver_side: 'Front driver side',
                        front_passenger_side: 'Front passenger side',
                        rear_driver_side: 'Rear driver\'s side',
                        rear_passenger_side: 'Rear passenger side',
                        used: 'Worn out, still good for 1-2 seasons',
                        change: 'To be changed',
                        new: 'New',
                        front_lights: "Front lights",
                        rear_lights: "Rear lights",
                        none: "None",
                        windshieldwiper_functional: "Windshield wipers",
                        mirror_functional: "Mirrors",
                        wheels: "Wheels",
                        remote_opening: "Remote opening: ",
                        plastics_trim: "Plastics and trim",
                        horn: "Horn",
                        door_locking: "Door locking",
                        fuel_door: "Fuel door"
                    },
                    tab_photos: {
                        general: 'General',
                        defects: 'Defects'
                    },
                },
            },
            purchase: {
                firstStepTitle: 'Inspection Type',
                secondStepTitle: 'Verify availability',
                isTest: 'This is a test inspection',
                make: 'Make',
                model: 'Model',
                year: 'Year',
                outsideZoneCheckbox: 'I confirm that I have contacted the Kiwiz team to verify the possibility of performing an inspection in this zone.',
                carfaxUrl: 'Carfax',
                mileage: 'Mileage',
                VIN: 'VIN',
                sellerTypes: {
                    title: 'Seller type',
                    dealership: 'Dealership',
                    private: 'Private seller',
                    owner: 'I am the owner',
                },
                purchaseType: 'Purchase Type',
                sellerCoordinates: 'Seller details',
                phoneNumber: 'Phone number',
                sellerName: 'Seller\'s name',
                sellerCity: 'Seller\'s city',
                dealerName: 'Dealer Name',
                adUrl: 'Ad URL',
                zipCode: 'Postal Code',
                fullAddress : 'Full address',
                price: 'Displayed price',
                buyerInfo: 'Personal details',
                firstName: 'First name',
                lastName: 'Last name',
                email: 'Email address',
                callAfterInspection: 'Would you like to be called after the inspection?',
                notes: 'Additionnal notes',
                terms: 'By pressing the button below, ',
                bookInspection: 'Book my inspection',
                payment: 'Payment',
                bookingConfirmation: 'Congratulations, your inspection request has been validated. Proceed to payment to confirm it.',
                pay: 'Pay',
                summary: 'Summary of ',
                back: 'Back',
                next: 'Next',
                availableZone: 'One or more inspectors cover this area.',
                unavailableZone: "This location is not covered by one of our inspectors.",
                leadOrigin: {
                    title: 'Where did you hear about Kiwiz? (special thanks from the marketing team 🤗)',
                    placeholder: 'Choose an option',
                    search: 'Internet search',
                    marketing: 'Facebook or Instagram ads',
                    kijiji: 'Kijiji Autos',
                    media: 'In the press, radio, etc.',
                    blog: 'Blog post or YouTube video',
                    mouth: 'Word of mouth',
                    other: {
                        title: 'Other',
                        placeholder: 'Specify',
                    },
                },
                carfax: {
                    title: 'Carfax report URL',
                    mainText: 'Even though it is not required, the carfax report will allow the inspector to check the vehicule\'s history and its consistency with the condition of the vehicle components inspected. The more information the inspector has, the more accurate the recommendation will be.',
                    subText: 'If you haven\'t done so, you can |purchase the CARFAX|. Make sure you get it from a reliable source. Only the CARFAX report link can be shared, any other document (photo, PDF, etc.) is not valid.',
                },
                inspectionCreditCode: {
                    title: 'Inspection credit code',
                    redeem: 'Use the credit',
                    error: {
                        title: 'This code ',
                        'Not found': 'does not exist',
                        'Already used': 'has already been used',
                    },
                    confirmation: {
                        title: 'Your inspection is now confirmed',
                        message: 'You will receive an email confirmation soon.'
                    }
                },
                customerLanguage: 'Customer language',
                createMandate: 'Create mandate',
                clientOwner: 'It\'s the client\'s car.',
                type: {
                    placeholder: 'Select the purchase type',
                    //complete: 'Regular inspection',
                    //iA: 'Industrial Alliance',
                    b2b: 'B2B',
                    b2c: 'B2C',
                }
            },
        },
        sequence: {
            rating: {
                question: 'Is this model interesting to you?'
            }
        },
        homepage: {
            logo_subtitle: 'Your trusted partner to buy your used car',
            tiles: {
                title: 'Welcome',
                search: {
                    title: 'Search',
                    description: 'Select your favorite models'
                },
                profile: {
                    personal_information: 'Personal information',
                    research_criteria: 'Criterias',
                    preferences: 'Preferences',
                    save: 'Save',
                    my_profile: 'My profile',

                    first_tab: {
                        first_name: 'First name',
                        last_name: 'Last name',
                        email: 'Email'
                    },

                    second_tab: {
                        search_radius: 'Search radius',
                        type_of_occasion: 'Type of occasion',
                        go_study: 'Go study',
                        go_work: 'Go work',
                        travel: 'Travel',
                        professional_use: 'Professional use(Eva,Uber,Etc.)',
                        drifting: 'Drifting in the Maxi parking lot',
                        other: 'Other',
                        fuel: 'Fuel',
                        electric: 'Electric',
                        Hybrid: 'Hybrid(electric and gasoline)',
                        gasoline: 'Gasoline',
                        no_preference: 'No preference',
                        number_of_passenger: 'Number of passengers'
                    },
                },
                ads: {
                    title: 'My ads',
                    description: 'View your ads'
                },
                criterias: {
                    title: 'Mes critères',
                    description: 'Modifie à tout moment tes critères de recherche'
                },
                insurance: {
                    title: 'Insurance',
                    description: 'Compare the insurance and find the cheapest'
                },
                ressources: {
                    title: 'Ressources',
                    description: 'Guides to help you every step of the way'
                },
                inspection: {
                    title: 'Inspection',
                    description: 'Make sure your future car is in good condition'
                },
            },
        },
    },
    sequence: {
        sequence: 'In progress',
        home: 'Dashboard',
        help: 'I need help',
        completed: 'completed',
        vehicle_suggestion: 'Tu dois avoir donné une note à chacun des véhicules avant de continuer 😉'
    },
    admin: {
        menu: {
            general: 'General',
            ads: 'Ads',
            guides: 'Guides',
            users: 'Users',
            clients: 'Clients',
            invoices: 'Invoicing',
            b2bClients: 'B2B',
            sequences: 'Sequences',
            settings: 'Settings',
            vehicles: 'Vehicles',
            todo: '\'TODO\' list',
            ressources: 'Ressources',
            summary: 'Summary',
            analytics: 'Analytics',
            payment: 'Remuneration',

        },
        general: {
            'waiting-list': {
                title: {
                    waiting: 'Awaiting suggestions',
                    'waiting/ads': 'Awaiting ads',
                    'waiting/purchase': 'Awaiting purchase',
                    inprogress: 'In progress'
                }
            },
            'total-users': 'Registered clients'
        },
        inspections_page: {
            tabs: {
                newInspectionTable: 'New inspections',
                ongoingInspectionTable: "Ongoing inspections",
                completedInspectionTable: "Completed inspections",
                incompleteInspectionTable: "Incomplete inspections",
                correctionInspectionTable: "Correction",
                problemInspectionTable: "Problems reported",
            },
            assign: "Assign",
            problem: 'Problem',
            remove: "Remove",
            assignToInspector: "Do you want to assign the inspection to this inspector: ",
            yes: "Yes",
            no: "No",
            assignmentTitle: "Assign to an inspector",
            daysOff: "day off",
            problemTable: {
                majorProblem: "Problems reported",
                problem: "Problem(s)",
                noResponse: "The seller does not respond",
                contactLater: "The seller will contact the inspector later",
                rescheduleInspection: "Seller wants to reschedule the inspection",
                alreadySold: "The car is already sold",
                sellerRefusal: "Seller refuses to have the car inspected",
                clientRequest: "Client requested a cancellation"
            },
            correctionTable: {
                inspector: "Inspecteur",
                action: "Action",
                fix: "Corriger",
                inspectionWithdrawn: "Inspection Withdrawn",
            },
            snackBar: {
                inspectionRemoved: "Inspection removed",
                searchStarted: "Search started",
                inspectionAssigned: "Inspection assigned",

            },
            searchBar : {
                clientFirstName : "Client first name",
                clientLastName : "Client last name",
                email : "Client email",
                inspectorFirstName : "Inspector first name",
                model : "Model",
                year : "Year",
                search : "Search",
                toggle : "Filter(s)",
            }
        },
        reusable_modal: {
            enter_date_days_off: "Enter the date of your day off",
            back: "Back",
            confirm: "Confirm"
        },
        sequence: {
            title: 'Sequences management',
            delete: 'Are you sure you want to delete this sequence?',
            new: 'New sequence',
            show_done_button: 'Show \'Next\' button',
            notify_admins: 'Notify admins when starting this step',
            waiting_on_expert_label: 'Text while waiting for a kiwiz expert',
            step: {
                'vehicle-suggestions': 'Vehicles Suggestions',
                text: 'Text',
                payment: 'Paiement'
            },
            hasEndModal: 'Show end modal',
            hasInitialModal: 'Show initial modal'
        },
        vehicles: {
            title: 'Vehicles',
            makes: 'Makes',
            types: 'Types',
            years: 'Years',
            modal: {
                title: 'Vehicle Modification',
                infos: 'INFOS',
                form: 'FORM',
                make: 'Make',
                model: 'Model',
                type: 'Type',
                drivetrain: 'Drivetrain',
                fuel: 'Fuel',
                consumption: 'Fuel consumption',
                maintenance: 'Maintenance',
                origin: 'Origin',
                warranty: 'Warranty',
                resell_value: 'Resell value',
                category: 'Category',
                advantages: 'Advantages',
                disadvantages: 'Disadvantages',
                image: 'Image',
                thumbnail: 'Thumbnail',
                images: 'Images',
                selection: 'Selection',
                search: 'Search',
                reliability: 'Reliability',
                money_value: 'Value for Money',
                money_value_short: 'Price',
                comfort: 'Comfort',
                fun_factor: 'Fun to Drive',
                fun_factor_short: 'Fun',
                security: 'Security',
                cargo_space: 'Cargo Space',
                recalls: 'Recalls',
                recommended_drivetrain: 'Recommended Drivetrain',
                year_min: 'Year (min)',
                year_max: 'Year (max)'
            }
        },
        users: {
            title: 'Users',
            display_name: 'Name',
            email: 'Email',
            progression: 'Progression',
            creation: 'Creation',
            lastSignInTime: 'Last Login',
            startDate:"Start Date",
            endDate:"End Date",
            modal: {
                title: 'User information',
                step: 'Step',
                completed_true: 'Completed',
                completed_false: 'In progress',
                suggest_vehicles: 'Suggest vehicles',
                suggestions: {
                    title: 'Suggestion de véhicules'
                },
                close_entry: 'Close entry',
                close_entry_question: 'Are you sure you want to close this entry?',
                reset_entry: 'Reset entry',
                reset_entry_question: 'Are you sure you want to reset this entry?',
                skip_step: 'Skip step',
                reset_step: 'Reset step',
                skip_step_question: 'Are you sure you want to skip this step for this user?',
                reset_step_question: 'Are you sure you want to reset this step for this user?',
                no_entries: 'No entries were found',
                no_ads: 'No ads were found',
                no_vehicle_selected: 'No vehicle selected',
                has_paid: 'Has paid for the service',
                has_bought: 'Has bought a car',
                isActive : 'Is active ?',
                adjustSalary : 'Salary',
                firstName : 'First name',
                lastName : 'Last name',
                description : 'Description in french',
                description_en : 'Description in english',
                maxTravelTime : 'Maximum travel time',
                zipcode : 'Zip code',
                phoneNumber : 'Phone number',
                email : 'Email address',
                inspectorRole : `Inspector role`,
                modificationButton : 'Save',
                unavailableDates: 'Unavailable Dates',
                archiveUser:  'Archive this user',
                archiveUserMessage: 'Are you sure you want to archive?',
                unarchiveUser:  'Unarchive this user',
                unarchiveUserMessage: 'Are you sure you want to unarchive?',
                typeOfUsers:  'Type of users',
                remuneration:  'Remuneration',
                pendingCandidate: 'Pending candidate',
                inspector:  'Inspector',
                isInsured: 'Insured',
                confirmAction: 'Confirm action',
                deleteImage: 'Delete Image',
                deleteImageQuestion: 'Are you sure you want to delete this image?',
                selectImage:  'Select Image',
            },
            search: {
                title: 'Search'
            },
            tabs: {
                sequences: 'Sequences',
                ads: 'Ads',
                pendingCandidates: 'Pending Candidates',
                activeCarInspectors:  'Active CarInspectors',
                temporarilyUnavailable:  'Temporarily Unavailable',
                archivedCarInspectors:  'Archived CarInspectors',
            },
            ads: {
                modal: {
                    title: 'Ad',
                    site: 'Site',
                    url: 'Url',
                    description: 'Description',
                    valid: 'The ad is valid',
                    invalid: 'The ad is invalid',
                    price: 'Price',
                    thumbnail: 'Thumbnail',
                    year: 'Year',
                    version: 'Version',
                    mileage: 'Mileage',
                    published: 'Published',
                    buying_budget: 'Buying Budget',
                    taxes: 'Taxes',
                    warranty: 'Warranties',
                    yearly_budget: 'Yearly Budget',
                    licenses: 'Licenses',
                    maintenance: 'Maintenance',
                    fuel: 'Fuel',
                    insurance: 'Insurance',
                    tax_dealer: 'Uses dealer taxes',
                    tax_person: 'Only one taxe is applied',
                    receive_announcement: 'Receive ads',
                    research_announcement: 'The search is based on your favorite models and the criteria added in the My criteria page.',
                    ready_to_receive: 'We need some more information in order to start the search. Please complete the following fields.'
                }
            }
        },
        inspectorsSummary: {
            title: "Inspector summary",
            inspectionCount: "Inspection count",
            adjustment: "Adjustment",
            total: "Total",
            adjust: "Adjust",
            reset: "Reset",
            activeInspectors: "Active inspectors",
            inactiveInspectors: "Inactive inspectors",
            unavailableInspectors: "Unavailable Inspectors",
            unavailableInspectorMessage: "No Unavailable Inspectors",
            startDate:"Start Date",
            endDate:"End Date",
            generateData: "Generate Data",
            dataAnalysis: "Data Analysis",
            type: "Type",
            client: "Client",
            chooseStartDate: 'Choose start date',
            chooseEndDate: 'Choose end date',
            temporaryUserMessage: 'No Temporary User',
            archivedInspectorMessage:  'No Archived Inspector',
        },
        ads: {
            title: 'Ads',
            creation: 'Création'
        },
        settings: {
            title: 'Settings',
            default_sequence: 'Default Sequence',
            vehicle_update_button: 'LeParking - Vehicles update',
            vehicle_update_message: 'Are you sure you want to update the vehicles? This action is VERY costly. (time + $)',
            vehicle_update_confirmation: 'Are you sure?',
            vehicle_update_confirmation_second: 'One last time. Are you sure?'
        },
        guides: {
            title: 'Guides',
            modal: {
                title: 'Guide Modification',
                name: 'Name',
                description: 'Description',
                enabled: 'Show it to users',
                link: 'Link to the website'
            }
        },
        reportModal: {
            title: "Report a problem",
            noResponse: "Seller do not response",
            contactLater: "Seller will contact me later",
            rescheduleInspection: "Seller wants to reschedule the inspection",
            other: "Other",
            alreadySold: "The car is already sold",
            sellerRefusal: "Seller refuses to have the car inspected",
            clientRequest: "Requested from client",
            back: 'Back',
            otherInput: 'Specify the problem you face, please',
            confirm: 'Confirm',
            createInspectionCredit: 'Create an inspection credit',
        },
        removeInspectorModal: {
            title: 'Do you want to remove the inspection from this inspector?',
            reason: 'What is the reason? It will be sent to the inspector.',
        },
        updateConfirmationModal: {
            title: 'Do you want to remove the inspection from this inspector?',
            reason: 'What is the reason? It will be sent to the inspector.',
        },
    },
    dashboard: {
        favorites: 'My selection',
        vehicles: 'Find a vehicle',
        create_own_favorite: 'It\'s up to you to create your list of favorite models!',
        search_specific_model: 'Search for specific vehicles, remove or add models!',
        search_best: 'Once your list of up to 3 models has been chosen, click on "Search for the best ads",',
        find_the_best_market: 'so that Kiwiz can find the best deals on the market!',
        search_best_button: 'Search for the best ads',
        no_favorite: 'You don\'t have any favorites, choose some and start your search!',
        vehicle_suggestion: 'You don\'t know which vehicles are right for you? Find out using our',
        recommendation_algorithm: 'recommendation algorithm',
        next_search: 'Your next search can be done in : ',
        hour: 'hour',
        searchPlaceholder: 'Find a vehicle',
        completedSelection: {
            title: 'Your list is full!',
            content: 'You can now start your search.',
            tooManyCars: 'You can only add 3 models to your selection. Make some changes or start your search.',
        },
        email: {
            title: 'Enter your email',
            invalid: 'Not a valid email',
        },
        zipCode: {
            title: 'Enter your zip code',
            invalid: 'Not a valid zip code',
        },
        gearbox: {
            title: 'Gearbox',
            none: 'No preference',
            automatic: 'Automatic',
            manual: 'Manual'
        },
        fuelType: {
            title: 'Fuel type',
            none: 'No preference',
            essence: 'Gas',
            diesel: 'Diesel',
            hybride: 'Hybrid',
            electrique: 'Electric',
            ethanol: 'Ethanol',
        },
        distance: {
            title: 'Search radius'
        },
        minYear: {
            title: 'Minimum year'
        },
        maxMileage: {
            title: 'Maximum mileage'
        },
        conditions: 'I agree to the |Kiwiz Terms and Conditions| and |Privacy Policies|.',
        launchSearch: 'Launch Search',
        validation: {
            title: 'Congratulations!',
            body: 'You will receive the best ads shortly by email.\n\nPsst: The email may arrive in promotions or SPAMS folder.',
        }
    },
    stripe: {
        method: 'Payment methods',
        thank_you: 'Thank you',
        validate_inspection: 'As soon as an inspector from our network validates the inspection, we will get back to you with the date and time of it.',
        amount_to_pay: 'Amont to pay: ',
        left_to_pay: 'Left to pay: ',
        the: 'the',
        summary_payment: 'Summary of purchase:',
        payment_made: 'Payment made!',

        success: {
            title: 'Payment successful. Thank you for your purchase!',
        },
        failure: {
            title: 'We could not proceed to payment. Your inspection is canceled.'
        },
        return: 'Back to website'
    },
    inspection: {
        fields: {
            constantAccelerationRoadTest: 'Normal warm-up with constant and linear acceleration',
            normalPressureTempRoadTest: 'Normal engine temperature and pressure build-up',
            goodLineKeeping: 'Good handling of the driving line (during acceleration and braking)',
            directionProperFunctioning: 'Good functioning of the steering (absence of play and vibrations)',
            smoothGearShift: 'Easy gear shifting and no snags on the gearbox synchros',
            goodClutch: 'Good clutch grip or no excessive torque converter slip',
            goodSuspension: 'Absence d\'anomalie au niveau des trains roulants (suspensions, amortisseurs, rotules, etc.)',
            engineVibration: 'No abnormal motor vibrations',
            brakeNoice: 'No vibrations or abnormal noises during braking',
            summerFloorMats: 'Summer carpet condition',
            winterFlootMats: 'Winter carpet condition',
            overallCleanliness: 'Overall cleanliness of the interior',
            headliner: 'Stains on the roof sky',
            frontSeats: 'Front seat stains',
            backSeats: 'Stains in the back seat ',
            insideTrunk: 'Stains or debris in the trunk',
            trunkLiner: 'Rust marks on the bottom of the trunk',
            floor: 'Floor condition',
            thicknessPaint: 'Paint thickness',
            frontBumper: 'Front bumper condition',
            backBumper: 'Condition of passenger side rear bumper',
            frontWindshieldWiperEsthetic: 'Condition of front windshield wipers',
            backWindshieldWiperEsthetic: 'Condition of rear windshield wipers',
            driverFrontOpticalBlock: 'Condition of the driver\'s side front headlight unit',
            driverBackOpticalBlock: 'Condition of the driver\'s side rear headlight unit',
            passengerFrontOpticalBlock: 'Condition of the front passenger side headlight unit',
            passengerBackOpticalBlock: 'Condition of the passenger side rear headlight unit',
            driverMirrorEsthetic: 'Driver\'s side front mirror condition',
            passengerMirrorEsthetic: 'Front passenger mirror condition',
            driverBackWing: 'Condition of the driver\'s side rear fender',
            driverFrontWing: 'Condition of the driver\'s side front fender',
            passengerBackWing: 'Condition of the rear fender on the passenger side',
            passengerFrontWing: 'Condition of the front fender on the passenger sider',
            driverFrontDoor: 'Driver\'s side front door condition',
            driverBackDoor: 'Condition of the driver\'s side rear door',
            passengerFrontDoor: 'Front passenger door condition',
            passengerBackDoor: 'Condition of the rear passenger door',
            driverUnderBody: 'Condition of the driver\'s side rocker panel',
            passengerUnderBody: 'Passenger side rocker panel condition',
            driverFrontRim: 'Condition of the front wheel on the driver\'s side',
            driverBackRim: 'Driver\'s side rear wheel condition',
            passengerFrontRim: 'Front wheel condition on passenger side',
            passengerBackRim: 'Condition of the rear wheel on the passenger side',
            hood: 'Condition of the hood',
            trunk: 'Trunk condition',
            roof: 'Roof condition',
            odor: 'Odor',
            safetyBelt: 'Safety belts',
            frontSeatsSettings: 'Front seat adjustments',
            audioSystem: 'Audio system and speakers',
            onboardComputer: 'Dashboard',
            frontWindshieldWiper: 'Front windshield wipers',
            backWindshieldWiper: 'Rear windshield wipers',
            airConditioning: 'Air conditioner',
            electricWindows: 'Electric windows',
            driverMirror: 'Driver\'s side mirror',
            interiorMirror: 'Interior mirror',
            passengerMirror: 'Passenger side mirror',
            gloveCompartment: 'Glove box',
            trunkOpening: 'Opening of the trunk',
            frontWindshield: 'Front windshield condition',
            backWindshield: 'Condition of the rear windshield',
            driverBackDoorOpening: 'Driver\'s side rear door opening',
            driverFrontDoorOpening: 'Driver\'s side front door opening',
            passengerFrontDoorOpening: 'Front door opening on passenger side',
            passengerBackDoorOpening: 'Opening of the rear passenger door',
            driverBackBrake: 'Driver\'s side rear brake condition',
            driverFrontBrake: 'Driver\'s side front brake condition',
            passengerFrontBrake: 'Front passenger side brake condition',
            passengerBackBrake: 'Passenger side rear brake condition',
            frontPassingLamps: 'Front passing lights',
            frontMainBeam: 'Front main beam headlights',
            frontHeadLights: 'Front headlights',
            frontFogLights: 'Front fog lights',
            frontFlashingSignalLamps: 'Front turn signals',
            insideLighting: 'inside lighting',
            backPositionLamps: 'Back tail lights',
            brakeLights: 'Brake lights',
            reversingLamps: 'Reverse lights',
            licensePlateLight: 'License plate lights',
            backFlashingSignalLamps: 'Back turn signals',
            accessoryBelt: 'Condition of the accessory belt',
            wiperFluidLevel: 'Windshield washer fluid level',
            brakeFluidLevel: 'Brake fluid level',
            motorOilLevel: 'Engine oil level',
            motorOilQuality: 'Engine oil quality',
            driverShockAbsorber: 'Condition of the front shock absorber on the driver\'s side',
            passengerShockAbsorber: 'Condition of the front shock absorber on the passenger side',
            driverFrontTirePressure: 'Driver side front tire pressure',
            passengerFrontTirePressure: 'Passenger side front tire pressure',
            driverBackTirePressure: 'Driver side rear tire pressure',
            passengerBackTirePressure: 'Passenger side rear tire pressure',
            driverFrontTireWear: 'Driver\'s side front tire wear',
            driverBackTireWear: 'Driver\'s side rear tire wear',
            passengerFrontTireWear: 'Front passenger tire wear',
            passengerBackTireWear: 'Passenger side rear tire wear',
            isOBDScanner: 'OBDScanner',
            driverFrontTire: "Driver side front tire",
            driverBackTire: "Driver side rear tire",
            passengerFrontTire: "Passenger side front tire",
            passengerBackTire: "Passenger side rear tire",
            plastics_trim: "Plastics and trim",
            Extra: "Photo",
            Extra2: "Photo",
            Extra3: "Photo",
            Extra4: "Photo",

        },

        photos: {
            'Avant du véhicule': 'Front of the vehicle',
            'Arrière du véhicule': 'Rear of the vehicle',
            'Trois-quart avant côté conducteur du véhicule': 'Front three-quarter on the driver\'s side of the vehicle',
            'Trois-quart avant côté passager du véhicule': 'Three-quarter front passenger side of the vehicle',
            'Côté conducteur du véhicule': 'Driver\'s side of the vehicle',
            'Côté passager du véhicule': 'Passenger side of the vehicle',
            'Trois-quart arrière côté conducteur du véhicule': 'Rear three-quarter on the driver\'s side of the vehicle',
            'Trois-quart arrière côté passager du véhicule': 'Three-quarter rear passenger side of the vehicle',
            'Compartiment moteur': 'Engine compartment',
            'Compartiment avant': 'Front compartment',
            'Compartiment arrière': 'Rear compartment',
            'Intérieur du coffre': 'Interior of the trunk',
            'Caméra de recul': 'Backup camera',
            'Système de navigation': 'Navigation system',
            carFront: 'Front of the vehicle',
            carBack: 'Rear of the vehicle',
            carThreeQuarterFrontDriver: 'Front three-quarter on the driver\'s side of the vehicle',
            carThreeQuarterFrontPassenger: 'Three-quarter front passenger side of the vehicle',
            carSideDriver: 'Driver\'s side of the vehicle',
            carSidePassenger: 'Passenger side of the vehicle',
            carThreeQuarterBackDriver: 'Rear three-quarter on the driver\'s side of the vehicle',
            carThreeQuarterBackPassenger: 'Three-quarter rear passenger side of the vehicle',
            engineBay: 'Engine compartment',
            frontCompartment: 'Front compartment',
            backCompartment: 'Rear compartment',
            trunkCompartment: 'Interior of the trunk',
            rearCamera: 'Backup camera',
            navigationSystem: 'Navigation system',
            driverFrontTire: "Driver side front tire",
            driverBackTire: "Driver side rear tire",
            passengerFrontTire: "Passenger side front tire",
            passengerBackTire: "Passenger side rear tire"
        },

        comments: {
            Mesure_hors_de_la_fourchette: 'Measurement outside the range',
            suspicion_d_un_accident: 'suspicion of an accident',
            Compris_dans_la_fourchette: 'Within range',
            aucune_preuve_d_accident: 'no evidence of accident',
            Rayures: 'Scratches',
            Excellent: 'Excellent',
            Bon: 'Good',
            Mauvais: 'Bad',
            Fonctionnelle: 'Functional',
            'Non-fonctionnelle': 'Non-Functional',
            'Non-fonctionnelles': 'Non-Functional',
            'Non-fonctionnels': 'Non-Functional',
            Fonctionnels: 'Functional',
            Rouille: 'Rust',
            Fissures: 'Cracks',
            Partiellement_opaque: 'Partially opaque',
            Opaque: 'Opaque',
            Présence_d_humidité: 'Presence of humidity',
            Brisé: 'Broken',
            Trous: 'Holes',
            'Impact(s)_présent(s)': 'Impact(s) present',
            'Très légèrement_opaque': 'Very slightly opaque',
            Retouches_peintures: 'Paint touch-up',
            Absence_d_une_visse: 'Absence of a screw',
            Rouille_de_surface: 'Surface rust',
            Bien: 'Good',
            Peu: 'Little',
            Aucune_odeur: 'No odor',
            Aucune: 'None',
            Excellente: 'Excellent',
            Beaucoup: 'A lot',
            Oui: 'Yes',
            Hiver: 'Winter',
            Été: 'Summer',
            Pneu_de_secours: 'Spare tire',
            Kit_de_changement_de_pneu: 'Tire change kit',
            'Kit_anti-crevaison': 'Anti-puncture kit',
            'Crochet_d\'attelage': 'Pintle hook',
            Aucun_équipement_présent: 'No equipment present',
            Usé: 'Used',
            'Bien_(moins_de_30-60_secondes)': 'Good (less than 30-60 seconds)',
            'Très_bien_(moins_de_10_secondes)': 'Very good (less than 10 seconds)',
            'Non_fonctionnelle_(on_l_attend_encore_depuis_mai)': 'Not functional',
            'Non-applicable': 'N/A',
            Anomalie: 'Anomaly',
            Correcte: 'Correct',
            Insuffisante: 'Insufficient',
            Trop_élevée: 'Too high',
            Trop_élevé: 'Too high',
            Non: 'No',
            Tabac: 'Tobacco',
            Animaux: 'Animals',
            Présence_d_un_ou_plusieurs_codes: 'Presence of one or more codes',
            Aucun: 'None',
            Hiver_clouté: 'Studded winter',
            '4_saisons': '4 seasons',
            Insuffisant: 'Insufficient',
            Bonne: 'Good',
            Mauvaise: 'Bad',
            Aucun_tapis_inclus: 'No carpet included',
            extra: "extra",
            extra2: "extra",
            extra3: "extra",
            extra4: "extra"
        },
    },
    notificationsPage:{
        errorMessage: 'No notifications',
        comment: "Comment",
        category: "Category",
        clientType: "Client Type",
        delete: "Delete",
        archive: "Archive",
        unarchive: "Unarchive",
        select: "Select category",
        all: "All",
        unavailability: "Unavailability",
        withdrawal: "Withdrawal",
        archived: "Archived",
        newAchievement: "New Achievement",

    },
    b2bClients:{
        displayName: "Display Name",
        loading: 'Loading invoices, please wait...',
        downloadSelected: 'Download Selected',
        invoiceNumber: 'Invoice Number',
        inspectionType: 'Inspection Type',
        invoicingDate: "Invoicing Date",
        amount: "Amount (taxes in)",
        actions: "Actions",
        view: "View",
        download: "Download",
        profile: "Profile",
        billing: "Billing",
        companyInfo: "Company Information",
        companyName: "Company Name",
        address: "Address",
        phoneNumber: "Phone Number",
        email:"Email",
        clientValue: "QuickBooks Client",
        representativeInfo: "Representative Information",
        firstName: "First Name",
        lastName:"Last Name",
        billingContactInfo: "Billing Contact Information",
        billedPrice: "Billing Price",
        completePrice: "Complete",
        psiDealerPrice: "PSI Dealer",
        psiCompoundPrice: "PSI Compound",
        tsDealershipPrice:"TS Dealer",
        tsLogisticsPrice: "TS Compound",
        invoices: "Invoices",
        clientsName: "Clients Name",
        clientName: "Client Name",
        price: "Price(Tax Ex.)",
        totalPriceUnpaid: "Total Price (Unpaid)",
        totalPriceSelected: "Total Price (Selected)",
        generateInvoice: "Generate Invoice",
        VIN: 'VIN/Vehicle Information',
        looksLike: "Looks like you're lost",
        filterNotFound: "Filter Not Found",
        modify: "Save",
    },
    paymentPage: {
        select: ' Select pay period ',
        selectedPeriod: 'Selected period',
        specialPay: 'Add special pay / bonus',
        addNote:  'Add a note',
        noInspectorMessage: 'No Inspector to be paid',
        bonus: 'Bonuses / Special pays',
        search: 'Search for an inspector',
        paid: 'Paid?',
        qty: 'Quantity',
    },
    bonusModal: {
        addSpecialPay: 'Add special pay / bonus',
        save: 'Save',
        bonusAmount: 'Amount',
        inspectionType: 'Inspection type',
        payDay:  'Pay day',
        inspector: 'Inspector',
        details:  'Details',
    },
    settingsPage:{
        listOfAdmins: 'List of Admins',
    },
};

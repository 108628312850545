import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ProfileUpdateComponent } from 'src/app/shared/modals/profile-update/profile-update.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  message!: string;
  
  constructor(public auth: AngularFireAuth, private authService: AuthService, private dialog : MatDialog) {
  }

  async ngOnInit() {
    let user = await this.auth.currentUser;
    if (!user?.displayName || user?.displayName == '') {
      this.dialog.open(ProfileUpdateComponent, {
        panelClass: ['modal', 'small'],
        maxWidth: "600px",
        data: {},
        autoFocus: false,
      });
    }
  }

  async sendVerificationCode() {
    var origin = localStorage.getItem("origin");
    let user = await this.auth.currentUser;
    user?.sendEmailVerification({url: environment.url + ((origin != null)? origin : "")});
    alert("Un nouveau code a été envoyé");
  }

  disconnect() {
    this.authService.disconnect();
  }

}

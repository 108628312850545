import { fr } from "../../assets/i18n/fr";
import { en } from "../../assets/i18n/en";
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';

/**
 * CREDIT: https://gist.github.com/ocombe/593d21598d988bf6a8609ba5fc00b67e#gistcomment-2124182
 */
export class CustomTranslateLoader implements TranslateLoader {
    public getTranslation(lang: string): Observable<any> {
        return new Observable(observer => {

            switch (lang) {
                case 'en':
                    observer.next(en);
                    break;

                case 'fr':
                default:
                    observer.next(fr);
                    break;
            }
            observer.complete();
        });
    }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'
import { LoadingComponent } from './components/loading/loading.component';
import { LoadingSecondaryComponent } from './components/loading/loading-secondary/loading-secondary.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material.module';
import { RouterModule } from '@angular/router';
import { ProfileUpdateComponent } from './modals/profile-update/profile-update.component';
import { ErrorNotFoundComponent } from './components/error-not-found/error-not-found.component';
import { MenuComponent } from './components/menu/menu.component';
import { MenuMobileComponent } from './components/menu/menu-mobile/menu-mobile.component';
import { UserImageComponent } from './components/user-image/user-image.component';
import { AccountHeaderComponent } from './components/menu/menu-mobile/account-header/account-header.component';
import { ErrorComponent } from './components/error/error.component';

const modals: any[] = [
  // AuthenticationGraphicsModalComponent,
  // AuthenticationGraphicsComponent,
  // IntroMessageComponent,
  ProfileUpdateComponent,
  // ModalVehicleSuggestionComponent,
  // SelectUserModalComponent,
  // ClientSequenceModalComponent
];

const components = [
  LoadingComponent,
  LoadingSecondaryComponent,
  // AuthenticationGraphicsComponent,
  ErrorNotFoundComponent,
  ErrorComponent,
  // AuthenticationGraphicsModalComponent,
  // KiwiRatingComponent,
  UserImageComponent,
  MenuComponent,
  MenuMobileComponent,
  AccountHeaderComponent,
  // FileUploadComponent,

  // Modals
  ...modals
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    TranslateModule,
    // PipesModule,
    MaterialModule,
    RouterModule,
    // CKEditorModule,
  ],
  entryComponents: modals,
  exports: [
    ...components,
    // PipesModule,
    MaterialModule,
    RouterModule,
    // CKEditorModule,
    TranslateModule
  ]
})
export class SharedModule { }
export const environment = {
  production: 'false',
  firebase: {
    apiKey: 'AIzaSyBdcHt8P0DBJjkpoq6r_Tq3oh7z8MPv9DE',
    authDomain: 'kiwiz-dev.firebaseapp.com',
    projectId: 'kiwiz-dev',
    storageBucket: 'kiwiz-dev.appspot.com',
    messagingSenderId: '11546753971',
    appId: '1:11546753971:web:5cd1a67bb4be82da2529c6',
    measurementId: 'G-94YRMF722L (modifié)',
  },
  apiUrl: 'https://staging.api.kiwiz.ca/v1.0',
  apiBaseUrl: 'https://staging.api.kiwiz.ca',
  url: 'https://staging.admin.kiwiz.ca',
  application_token: 'ff3e771c-eb08-11ea-9366-5076afa7169c',
  reportUrl: 'https://staging.app.kiwiz.ca/client/inspection/',
  incompletePurchaseUrl: 'https://staging.app.kiwiz.ca/client/purchase/',
  google_maps_key: 'AIzaSyBBRduREYy8aYVshvLLRjKlqJ8Bez22LxU'
};

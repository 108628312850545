<div class="menu">
    <div class="logo">
        <img src="assets/carInspectTransparent.png">
    </div>

    <section class="user" *ngIf="showUser && user">
        <img class="picture" *ngIf="!user.displayName || user.displayName == ''; else image" src="{{ (user.photoURL) ? user.photoURL : '/assets/icon.jpeg' }}" alt="">
        <ng-template #image>
            <app-user-image [user]="user"></app-user-image>
        </ng-template>
        <p>{{ user.displayName }}</p>
    </section>

    <ng-content></ng-content>

    <button class="disconnect" (click)="disconnectUser()">
        {{ 'general.disconnect' | translate }}
    </button>
</div>
import { Component, NgZone, OnInit } from '@angular/core';
import { getIdToken, sendEmailVerification } from '@angular/fire/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import firebase from 'firebase/compat/app';
import packageJson from 'package.json';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent implements OnInit {

  email!: string;
  password!: string;

  isLoading: boolean = false;
  isAllLoading: boolean = false;
  originParam!: string;
  type: string;
  isFrench = false;

  error!: string;
  public version: string = packageJson.version;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public fauth: AngularFireAuth,
    public translate: TranslateService,
    private ngZone: NgZone,
    public dialog: MatDialog,
    private authService: AuthService
  ) {
    this.type = this.route.snapshot.data['type'];
    authService.isAuthenticated().then((isAuthenticated) => {
      if (isAuthenticated) {
        authService.getUser().then((user) => {
          if (user?.emailVerified) {
            if (user?.role == "admin") {
              this.router.navigate(['admin', 'inspections']);
            } else {
              window.location.href = "https://kiwiz.ca";
            }
          }
        }, (error) => this.errorHandling(error));
      }
    });

    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  }

  ngOnInit() {
  }

  async loginGoogle() {
    this.isLoading = true;
    if (this.originParam != null) {
      localStorage.setItem("origin", this.originParam);
    }
    await this.fauth.signInWithRedirect(new firebase.auth.GoogleAuthProvider()).catch((error) => this.errorHandling(error));
  }

  async loginFacebook() {
    if (this.originParam != null) {
      localStorage.setItem("origin", this.originParam);
    }
    this.fauth.signInWithRedirect(new firebase.auth.FacebookAuthProvider()).catch((error) => this.errorHandling(error));
  }

  async loginEmail() {
    if (this.email != null && this.email != "" && this.password != null && this.password != "") {
      this.isLoading = true;
      await this.fauth.signInWithEmailAndPassword(this.email, this.password).then((login) => { this.login(); }).catch((error) => this.errorHandling(error));
    } else {
      this.translate.get('errors.missing-infos').subscribe((res: string) => {
        this.error = res;
      });
    }
  }

  async signupEmail() {
    this.isLoading = true;
    if (this.originParam != null) {
      localStorage.setItem("origin", this.originParam);
    }

    if (!this.email || !this.password) {
      return;
    }

    await this.fauth.createUserWithEmailAndPassword(this.email, this.password).then(async (user) => {
      var origin = localStorage.getItem("origin");
      let currentUser = await this.fauth.currentUser;
      if (currentUser) sendEmailVerification(currentUser, { url: environment.url + ((origin != null) ? origin : "") });
      this.ngZone.run(() => this.login());
    }, (error) => this.errorHandling(error));
  }

  async login() {
    let currentUser = await this.fauth.currentUser;
    let idToken: string | null = null;
    if (currentUser) idToken = await getIdToken(currentUser);
    if (idToken) this.authService.login(idToken, this.originParam);
  }

  async errorHandling(error: any) {
    if (error['code'] == null) {
      this.isLoading = false;
      return;
    }

    this.translate.get(error['code'].toString().replace('auth/', 'errors.')).subscribe((res: string) => {
      if (res) {
        this.error = res;
      } else {
        this.translate.get('errors.error').subscribe((res: string) => {
          this.error = res;
        });
      }
    });

    this.isLoading = false;
  }

  switchLanguage(language: string): void {
    this.translate.use(language);
    this.isFrench = language === "fr";
  }

}

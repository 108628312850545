import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, lastValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth/auth.service';

/**
 * This service intercepts every http request made using HttpClient from Angular
 * and adds a header with the authorization token and other informations.
 */
@Injectable({
  providedIn: 'root'
})
export class HttpHeaderIntercepter implements HttpInterceptor {

  constructor(public auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(req, next))
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    let token = await this.auth.token();

    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        enctype: 'multipart/form-data',
        'x-auth-token': environment.application_token.toString()
      }
    })

    return await lastValueFrom(next.handle(req));
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationComponent } from './authentication.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { PasswordResetComponent } from './modals/password-reset/password-reset.component';
import { LoginComponent } from './components/login/login.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { SignupComponent } from './components/signup/signup.component';
import { FirebaseComponent } from './components/firebase/firebase.component';

const components = [
  AuthenticationComponent,
  LoginComponent,
  ConfirmationComponent,
  SignupComponent,
  PasswordResetComponent,
  FirebaseComponent,
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: components
})
export class AuthenticationModule { }

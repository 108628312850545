import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class EmailValidationGuardService implements CanActivate {

  constructor(public auth: AuthService, public router: Router) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const user = await this.auth.getUser();

    if (user?.emailVerified) {
      return true;
    } else {
      this.router.navigate(["confirmation"]);
      return false;
    }
  }
}

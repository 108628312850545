<div class="error" [ngSwitch]="error.status">
    <ng-container *ngSwitchCase="404">
        <div class="c-logo">
            <img src="/assets/404.png" alt="">
        </div>
        <p>{{ 'errors.not-found' | translate }}</p>
    </ng-container>

    <ng-container *ngSwitchDefault>
        <div class="c-logo">
            <img src="/assets/bug.png" alt="">
        </div>
        <p>{{ 'errors.error' | translate }}</p>
    </ng-container>
</div>
<div class="content">
    <a routerLink="/">{{ 'general.return-to-site' | translate }}</a>
    <div class="c-logo">
        <img src="/assets/logo.png" alt="">
    </div>
    <div class="c-title">
        <h1>404</h1>
        <h2>{{ 'errors.page-not-found' | translate }}</h2>
    </div>
    <div class="c-graphic">
        <img src="/assets/404.png" alt="">
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-firebase',
  templateUrl: './firebase.component.html',
  styleUrls: ['./firebase.component.scss']
})
export class FirebaseComponent implements OnInit {

  mode!: string;
  oobCode!: string;
  password!: string;
  confirmation!: string;

  constructor(private router: Router, private route: ActivatedRoute, private auth: AngularFireAuth) {
    this.route.queryParams.subscribe(async (params) => {
      this.oobCode = params["oobCode"];
      console.log("MODE", this.mode);
      console.log("OOB", this.oobCode);
      await this.auth.checkActionCode(this.oobCode).then((value) => {
        this.mode = value.operation;
        console.log(value);
        if (value.operation == 'VERIFY_EMAIL') {
          this.auth.applyActionCode(this.oobCode);
        }
      }, (error) => {
        console.log(error);
      })

    });
  }
  ngOnInit() {
  }

  async resetPassword() {
    await this.auth.confirmPasswordReset(this.oobCode, this.password).then(() => {
      this.router.navigate(['client', 'informations']);
    });
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationComponent } from '../../authentication.component';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  
  @Input() authenticationComponent!: AuthenticationComponent;

  confirmation!: string;
  error!: string;

  constructor(private location : Location, private router : Router, private route: ActivatedRoute) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  signup() {
    if (this.authenticationComponent.password == this.confirmation) {
      this.authenticationComponent.signupEmail();
    } else {
      this.error = "Votre mot de passe et la confirmation doivent correspondre.";
    }
  }

  buttonStatus() : boolean {
    if (!this.confirmation || !this.authenticationComponent.password || !this.authenticationComponent.email) {
      return false;
    }

    return true;
  }

}

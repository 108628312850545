<div class="auth-bg"></div>

<div class="content">
    <section>
        <div class="header">
            <div class="logo">
                <img src="/assets/logo.png" alt="">
            </div>
            <h2 class="form-title">{{ 'firebase.' + mode + '.title' | translate }}</h2>
        </div>
        <ng-container [ngSwitch]="mode">
            <div *ngSwitchCase="'VERIFY_EMAIL'">
                <h3>{{ 'firebase.VERIFY_EMAIL.content' | translate }}</h3>
                <button [routerLink]="'/client/informations'">{{ 'general.continue' | translate }}</button>
            </div>
            <div *ngSwitchCase="'PASSWORD_RESET'">
                <h3>{{ 'firebase.PASSWORD_RESET.content' | translate }}</h3>
                <input type="password" [(ngModel)]="password" [placeholder]="'general.password' | translate">
                <input type="password" [(ngModel)]="confirmation" [placeholder]="'general.confirmation' | translate">

                <div class="error-inline" *ngIf="password != null && confirmation == password && password.length < 6">
                    <p>{{ 'errors.weak-password' | translate }}</p>
                </div>
                <button (click)="resetPassword()" [disabled]="password != confirmation || password == null || password.length < 6">{{ 'general.continue' | translate }}</button>
            </div>
        </ng-container>
    </section>
</div>
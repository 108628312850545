<div class="header-content" [ngClass]="{ 'showLogo' : showLogo }">
    <ng-container *ngIf="user; else public">
        <div class="logo" *ngIf="showLogo">
            <img src="/assets/logo.png" alt="">
        </div>
        <div class="user-wrap">
            <div class="user-email">
                {{ user.email }}
            </div>
            <img class="picture" src="{{ (user.photoURL) ? user.photoURL : '/assets/icon.jpeg' }}" alt="">
            <div *ngIf="home" class="c-more-modal-link home"
                [routerLink]="(user.role == 'client') ? '/client/informations' : '/admin'">
                <svg>
                    <use xlink:href="/assets/menu_client.svg#home"></use>
                </svg>
            </div>
            <ng-container *ngIf="disconnect">
                <div class="c-more-modal-link disconnect" (click)="disconnectUser()">
                    <svg>
                        <use xlink:href="/assets/sprite.svg#wico-log-out"></use>
                    </svg>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <ng-template #public>
        <ng-container *ngIf="!mustBeLoggedIn">
            <button>{{ 'authentication.connect' | translate }}</button>
        </ng-container>
    </ng-template>
</div>
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'kiwiz-admin';

  constructor(
    private _translate: TranslateService,
    private _router: Router
  ) {
    this._handleTanslation();
  }

  private _handleTanslation() {
    const navigatorLanguage = navigator.language;
    const userLang = (navigatorLanguage.includes('en')) ? 'en' : 'fr';
    this._translate.setDefaultLang('fr');
    this._translate.use('fr' || userLang);
  }
}
